import { useNavigate } from 'react-router-dom';
import DetailsSvg from '../../svgs/map/Details';
import SmallHeader from '../../svgs/tracking/SmallHeader'
import ChartChainLink from '../../svgs/ChartChainLink'
import WatchAlertList from '../watchPage/WatchAlertList';
import CopyLink from '../CopyLink';
//
import DetailArrowSvg from '../../svgs/summary/DetailArrowSvg'
import ImgSvg from '../../svgs/summary/ImgSvg'
import PlaySvg from '../../svgs/summary/PlaySvg'
export default function Component(props){
    const navigate = useNavigate()
    return(
        <div className="w-full flex flex-col text-white">
            <div className="flex flex-row w-full justify-between items-center pb-[8px]" style={{}}>
                <div className="text-[16px] font-[300] flex flex-row items-center gap-2"><SmallHeader />Smart-Watch Alerts<CopyLink /></div>
                {!props.isReport &&<div className='text-[12px] flex flex-row justify-center gap-2  cursor-pointer'onClick={() => {navigate('/smartwatch')}}>Details<DetailArrowSvg /></div>}
            </div>
            <WatchAlertList isReport={props.isReport}/>
        </div>
    )
}