import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import Loading from '../components/Loading';
import DropDown from '../components/DropdownMenu'
import { yearMonthDay, handleFilterDate, handleFilterDateText } from "../actions/actionsDateTime";
//svg
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import ChartChainLink from '../svgs/ChartChainLink'
import DotDotDotSvg from '../svgs/tracking/DotDotDotSvg'
import { createNewVrRecord, findVrRecordsWithOptions, editVrRecord, deleteVrRecord } from '../services/vr.service';
import PageSelector from '../components/PageSelector';


const CHART_TITLE = [
    // {title: '', field: null, flex: 1},
    {title: 'Item', field: '_id', flex: 1},
    {title: 'Course Date & Time', field: 'courseDateTime', flex: 3},
    {title: 'Course Name', field: 'courseName', flex: 2},
    {title: 'Lecturer', field: 'lecturer', flex: 2},
    {title: 'Category', field: 'courseCategory', flex: 2},
    {title: 'Last Update By', field: 'modifiedBy', flex: 2},
    {title: 'Last Update Time', field: 'modifyDate', flex: 2},
    {title: 'Action', field: '', flex: 1},
]


const BinIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={19} fill="none" {...props}>
      <g clipPath="url(#abinicon)">
        <path fill="#F47070" d="M4.5 14.75c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-9h-9v9ZM6 7.25h6v7.5H6v-7.5Zm5.625-3.75-.75-.75h-3.75l-.75.75H3.75V5h10.5V3.5h-2.625Z"/>
      </g>
      <defs>
        <clipPath id="abinicon">
          <path fill="#fff" d="M0 .5h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  )


function Component(props){
    const fromRef = useRef()
    const toRef = useRef()
    const [popType, setPopType] = useState(undefined)
    const navigate = useNavigate();
    const [isLoading,setisLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const filterDate = (searchParams.get('date') || 'Today')
    const filterFrom = searchParams.get('from')
    const filterTo = searchParams.get('to')


    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=10;
    const [total,setTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    const handleSelectPage=(p)=>async ()=>{
        if(p > totalPage || p < 1) return;
        searchParams.delete('page')
        searchParams.set('page', p)
        setSearchParams(searchParams)
    }
    //end of paging


    const [data,setData]=useState([])
    const sortBy={createDate:-1};
    
    const fetchVrRecord=async(p=page,l=limit,st=sortBy)=>{
        // const now=Date.now();
        // const day = filterState==='24H'?24:filterState==='7D'? 7*24:filterState==='30D'? 30*24:24
        // const filter={
        //     courseDateTime:{ $gte: new Date(now-1000*60*60*day), $lte: new Date(now+1000*60*60*day) }
        // }
        const filter = {projectId:props.control.projectId}
        if(filterDate) filter['courseDateTime'] = handleFilterDateText(filterDate)
        if(filterFrom && filterTo) filter['courseDateTime'] = handleFilterDateText('', filterFrom, filterTo)
        let result = await findVrRecordsWithOptions(props.token.token,filter,{ skip: l*(p-1), limit:l , sort:st})
        if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
        }
        
    }



    useEffect(() => {
        const timer = setTimeout(() => {fetchVrRecord()}, 500)
        return () => clearTimeout(timer)
    },[searchParams])

    useEffect(()=>{
        setSearchParams({page:1})
    },[props.control.projectId])



    //new record params
    const [newRecord,setNewRecord]=useState(null)
    const [newRecordError,setnewRecordError]=useState(null)
    function handleStartNewRecord(){
        setNewRecord({})
    }
    function handleCancelNewRecord(){
        setNewRecord(null)
        setnewRecordError('')
    }
    function handleAddParticipants(){
        let r= {...newRecord}
        r.participants=[...(r?.participants||[]),{}]
        setNewRecord(r)
    }
    const handleParticipantsInput=(field,item,index)=>(e)=>{
        let r= {...newRecord}
        r.participants[index]={...(r.participants[index]||{}),[field]:e.target.value}
        setNewRecord(r)
    }
    const removeParticipants=(item,index)=>()=>{
        let r= {...newRecord}
        r.participants.splice(index, 1)
        setNewRecord(r)
    }
    const handleNewRecordInput=(field)=>(e)=>{
        let r= {...newRecord}
        r[field]=e.target.value
        setNewRecord(r)
    }
    const handleAddNewRecord=async()=>{
        if(!newRecord?.courseName){setnewRecordError('Please enater course name.');return}
        if(!newRecord?.lecturer){setnewRecordError('Please enater lecturer name.');return}
        else if(!newRecord?.courseCategory){setnewRecordError('Please enter course category.');return}
        else if(!newRecord?.courseDateTime){setnewRecordError('Please select date and time.');return}
        else if(!newRecord?.courseContent){setnewRecordError('Please enter course content.');return}
        else {setnewRecordError('')}
        if(isLoading)return
        setisLoading(true)
        let data = {
            projectId: props.control.projectId,
            courseDateTime: newRecord?.courseDateTime?new Date(newRecord?.courseDateTime):new Date(),
            courseName: newRecord?.courseName||'',
            lecturer: newRecord?.lecturer||'',
            courseCategory: newRecord?.courseCategory||'',
            courseContent: newRecord?.courseContent||'',
            participants: newRecord?.participants||[],
        }
        let result = await createNewVrRecord(props.token?.token,data);
        if(result.errno){
            setnewRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelNewRecord()
            fetchVrRecord()
        }
        setisLoading(false);
    }
    //end of new record params

    //edit record params
    const [editRecord,seteditRecord]=useState(null)
    const [editRecordError,seteditRecordError]=useState(null)
    const handleStartEditRecord=(data)=>()=>{
        seteditRecord(data)
    }
    function handleCancelEditRecord(){
        seteditRecord(null)
        seteditRecordError('')
    }
    function handleEditAddParticipants(){
        let r= {...editRecord}
        r.participants=[...(r?.participants||[]),{}]
        seteditRecord(r)
    }
    const handleEditParticipantsInput=(field,item,index)=>(e)=>{
        let r= {...editRecord}
        r.participants[index]={...(r.participants[index]||{}),[field]:e.target.value}
        seteditRecord(r)
    }
    const removeEditParticipants=(item,index)=>()=>{
        let r= {...editRecord}
        r.participants.splice(index, 1)
        seteditRecord(r)
    }
    const handleEditRecordInput=(field)=>(e)=>{
        let r= {...editRecord}
        r[field]=e.target.value
        seteditRecord(r)
    }
    const handleEditRecord=async()=>{
        if(!editRecord?.courseName){seteditRecordError('Please enater course name.');return}
        if(!editRecord?.lecturer){seteditRecordError('Please enater lecturer name.');return}
        else if(!editRecord?.courseCategory){seteditRecordError('Please enter course category.');return}
        else if(!editRecord?.courseDateTime){seteditRecordError('Please select date and time.');return}
        else if(!editRecord?.courseContent){seteditRecordError('Please enter course content.');return}
        else {seteditRecordError('')}
        if(isLoading)return
        setisLoading(true)
        let data = {
            projectId: props.control.projectId,
            courseDateTime: editRecord?.courseDateTime?new Date(editRecord?.courseDateTime):new Date(),
            courseName: editRecord?.courseName||'',
            lecturer: editRecord?.lecturer||'',
            courseCategory: editRecord?.courseCategory||'',
            courseContent: editRecord?.courseContent||'',
            participants: editRecord?.participants||[],
        }
        let result = await editVrRecord(props.token?.token,editRecord?._id,data);
        if(result.errno){
            seteditRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelEditRecord()
            fetchVrRecord()
        }
        setisLoading(false);
    }
    //end of edit record params

    //delete params
    const [deleteRecord,setdeleteRecord]=useState(null)
    const [deleteRecordError,setdeleteRecordError]=useState(null)
    const handleCancelDeleteRecord=()=>{setdeleteRecord(null)}
    const handleStartDeleteRecord=()=>{
        setdeleteRecord(true)
    }
    const handleDeleteRecord=async()=>{
        if(isLoading)return
        setisLoading(true)
        let result = await deleteVrRecord(props.token?.token,editRecord?._id);
        if(result.errno){
            setdeleteRecordError(`Error: ${JSON.stringify(result?.message)}`)
        }else{
            handleCancelDeleteRecord()
            handleCancelEditRecord()
            fetchVrRecord()
        }
        setisLoading(false);
    }
    //end of delete params
    function handleParamChange(key, val){
        searchParams.delete(key)
        if(['from','to'].includes(key)) searchParams.delete('date')
        if(key === 'date'){
            searchParams.delete('from')
            searchParams.delete('to') 
        }
        if(key === 'clearDate') {
            searchParams.delete('from')
            searchParams.delete('to')
            searchParams.delete('date')
            fromRef.current.value = ""
            toRef.current.value = ""
        }
        if(val !== '' && key !== 'clearDate') searchParams.set(key, val)
        setSearchParams(searchParams)
    }
    function handleDateChange(val){
        if(val !== 'Select Date') return handleParamChange('date', val)
        setPopType('date')

    }
    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-[14px] text-white'>
            <div style={{...LEFTRIGHT_PADDING}} className='pb-64'>
                <div className="flex flex-row my-[40px]">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold ">VR Training Course Attendance Record</div>
                </div>


                <div className='flex flex-col w-full' style={{}}>
                    {/*header/filter/buttons*/}
                    <div className="rounded-xl mt-[16px]"style={{}}>
                        <div className="flex flex-row items-center justify-between py-[24px] px-[16px]" style={{borderBottomWidth: .5, borderBottomColor:'#FFFFFF80'}}>
                            <div className="flex flex-row items-center">
                                <div className="text-white text-[18px] font-bold flex items-center">Attendance Record</div>
                                <ChartChainLink className="mx-[10px] cursor-pointer"/>
                            </div>
                            <div className='flex flex-row gap-4 flex-1 justify-end items-center'>
                                {/* <div className='flex flex-row max-w-[440px] flex-1 items-center border border-[#00202A] rounded-full overflow-hidden'>
                                    {Object.keys(TIME_FILTER).map((time,index) => (
                                        <div key={index} onClick={() => setFilterState(time)} className='flex-1 text-center text-white select-none py-[2px] hover:font-bold cursor-pointer' style={{borderRightColor:'#00202A', borderRightWidth: time === 'Custom' ? 0 : 1, backgroundColor: filterState === time ? '#00376A' : 'transparent'}}>
                                            {time}
                                        </div>
                                    ))}
                                </div> */}
                                <DropDown value={(filterFrom && filterTo ? `${filterFrom} / ${filterTo}` :filterDate)} select = {handleDateChange}/>
                                <div onClick={handleStartNewRecord} className="button_blue" style={{}}>
                                    + Create Record
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end of header/filter/buttons*/}

                    {/*data list*/}
                    <div className="py-[32px] px-[30px]">
                        <div className="flex flex-row">
                            {CHART_TITLE.map((obj, i) => (
                                <div key={i} className="font-bold text-white px-[16px] text-pretty flex flex-row items-center gap-2 pb-[24px]" style={{flex: obj.flex || 1, flexShrink: obj.srink ? 2 : 0}}>
                                    {obj.title}
                                </div>
                            ))}
                        </div>
                        {data.map((item, idx) =>
                            <div key={item?._id||idx} className="flex flex-row py-[21px]" style={{overflowWrap: 'break-word',backgroundColor:idx%2===1?'#FFFFFF0D':'transparent'}}>
                                {CHART_TITLE.map((h, i) => 
                                    <div key={'TrackP2' + h.title + i} className="text-white px-[16px] flex flex-row items-center" style={{flex: h.flex}} onClick={() => {}}>
                                        {
                                            h.title === 'Item' ? idx + 1 :
                                            h.title === 'Course Date & Time' ? `${new Date(item[h?.field]).toLocaleString()}`:
                                            h.title === 'Last Update Time' ?  `${new Date(item[h?.field]).toLocaleString()}`:
                                            h.title === 'Action' ? <div className='cursor-pointer' onClick={handleStartEditRecord(item)}><DotDotDotSvg color={'#63BDFF'}/> </div>:
                                            h.title === 'Last Update By' ? <div className='flex flex-col'>
                                                <div>{item?.modifiedByUser?.[0]?.type||'-'}</div>
                                                <div className='text-[12px] text-[#A1A1A1]'>{item?.modifiedByUser?.[0]?.username||'-'}</div>
                                            </div> :
                                           `${item[h?.field]||'-'}`
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                     {/*end of data list*/}

                    <PageSelector
                        handleSelectPage={handleSelectPage}
                        page={page}
                        total={total}
                        totalPage={totalPage}
                    />

                </div>
            </div>



            {/*add new record popup*/}
            {newRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Add New Attendance Record</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelNewRecord}>X</div>         

                    <div className='font-bold text-sm mt-10'>Course Name*</div>
                    <input value={(newRecord?.courseName)||""} onChange={handleNewRecordInput('courseName')} placeholder='course name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-10'>Lecturer Name*</div>
                    <input value={(newRecord?.lecturer)||""} onChange={handleNewRecordInput('lecturer')} placeholder='Lecturer name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Category*</div>
                    <input value={(newRecord?.courseCategory)||""} onChange={handleNewRecordInput('courseCategory')} placeholder='category' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Date & Time</div>
                    <input value={
                        newRecord?.courseDateTime
                        ? new Date(new Date(newRecord?.courseDateTime).getTime()+1000*60*60*8)?.toISOString().slice(0, 19)
                            : new Date(Date.now()+1000*60*60*8).toISOString().slice(0, 19)
                    } onChange={handleNewRecordInput('courseDateTime')} type='datetime-local' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white',colorScheme: 'dark'}}/>

                    <div className='font-bold text-sm mt-5'>Course Content*</div>
                    <textarea value={(newRecord?.courseContent)||""} onChange={handleNewRecordInput('courseContent')} placeholder='course content' className='bg-transparent rounded-[4px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Participants</div>
                    <div className='w-full flex flex-row text-xs mt-1 bg-[#ffffff30]' style={{minWidth:600}}>
                        <div className='flex-1 px-[4px] py-[10px] border'>Name</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Employer</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Trade</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Safety Card No.</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Remark</div>
                    </div>
                    {(newRecord?.participants||[]).map((item,index)=>
                        <div key={item?._id||index} className='w-full flex flex-row text-xs relative items-center' style={{}}>
                            <div className='flex-1 border'>
                                <input value={item?.name||''} onChange={handleParticipantsInput('name',item,index)} placeholder='name' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.employer||''}  onChange={handleParticipantsInput('employer',item,index)} placeholder='employer' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.trade||''}  onChange={handleParticipantsInput('trade',item,index)} placeholder='trade' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.cardNo||''}  onChange={handleParticipantsInput('cardNo',item,index)} placeholder='card number' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border relative'>
                                <input  value={item?.remark||''}  onChange={handleParticipantsInput('remark',item,index)} placeholder='remark' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div onClick={removeParticipants(item,index)} className='absolute left-[100%] cursor-pointer'>
                                <BinIcon/>
                            </div>
                        </div>
                    )}
                    <div onClick={handleAddParticipants} className='w-full flex justify-center items-center px-[4px] py-[2px] bg-[#ffffff30] border text-xl hover:font-bold cursor-pointer select-none'>
                        +
                    </div>

                    <div className='w-full text-[red] text-xs'>{newRecordError}</div>

                    <div onClick={handleAddNewRecord} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[4px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Add
                    </div>
                </div>
            </div>}
            {/*end of add new record popup*/}


            {/*edit record popup*/}
            {editRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Edit Attendance Record</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelEditRecord}>X</div>         

                    <div className='font-bold text-sm mt-10'>Course Name*</div>
                    <input value={(editRecord?.courseName)||""} onChange={handleEditRecordInput('courseName')} placeholder='course name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-10'>Lecturer Name*</div>
                    <input value={(editRecord?.lecturer)||""} onChange={handleEditRecordInput('lecturer')} placeholder='Lecturer name' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Category*</div>
                    <input value={(editRecord?.courseCategory)||""} onChange={handleEditRecordInput('courseCategory')} placeholder='category' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Date & Time</div>
                    <input value={
                            typeof editRecord?.courseDateTime==='string'
                            ? new Date(new Date(editRecord?.courseDateTime).getTime()+1000*60*60*8)?.toISOString().slice(0, 19)
                            : new Date(Date.now()+1000*60*60*8).toISOString().slice(0, 19)
                        } onChange={handleEditRecordInput('courseDateTime')} type='datetime-local' className='bg-transparent rounded-[99px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white',colorScheme: 'dark'}}
                    />

                    <div className='font-bold text-sm mt-5'>Course Content*</div>
                    <textarea value={(editRecord?.courseContent)||""} onChange={handleEditRecordInput('courseContent')} placeholder='course content' className='bg-transparent rounded-[4px] text-sm px-[16px] py-[6px] mt-1' style={{border:'.5px solid white'}}/>

                    <div className='font-bold text-sm mt-5'>Participants</div>
                    <div className='w-full flex flex-row text-xs mt-1 bg-[#ffffff30]' style={{minWidth:600}}>
                        <div className='flex-1 px-[4px] py-[10px] border'>Name</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Employer</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Trade</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Safety Card No.</div>
                        <div className='flex-1 px-[4px] py-[10px] border'>Remark</div>
                    </div>
                    {(editRecord?.participants||[]).map((item,index)=>
                        <div key={index} className='w-full flex flex-row text-xs relative items-center' style={{}}>
                            <div className='flex-1 border'>
                                <input value={item?.name||''} onChange={handleEditParticipantsInput('name',item,index)} placeholder='name' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.employer||''}  onChange={handleEditParticipantsInput('employer',item,index)} placeholder='employer' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.trade||''}  onChange={handleEditParticipantsInput('trade',item,index)} placeholder='trade' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border'>
                                <input value={item?.cardNo||''}  onChange={handleEditParticipantsInput('cardNo',item,index)} placeholder='card number' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div className='flex-1 border relative'>
                                <input  value={item?.remark||''}  onChange={handleEditParticipantsInput('remark',item,index)} placeholder='remark' className='w-full px-[4px] py-[10px] bg-transparent'/>
                            </div>
                            <div onClick={removeEditParticipants(item,index)} className='absolute left-[100%] cursor-pointer'>
                                <BinIcon/>
                            </div>
                        </div>
                    )}
                    <div onClick={handleEditAddParticipants} className='w-full flex justify-center items-center px-[4px] py-[2px] bg-[#ffffff30] border text-xl hover:font-bold cursor-pointer select-none'>
                        +
                    </div>

                    <div className='w-full text-[red] text-xs'>{editRecordError}</div>

                    <div onClick={handleEditRecord} className='w-full text-[#63BDFF] bg-[#00202A] mt-5 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#10303A] hover:font-bold select-none cursor-pointer'>
                        Update
                    </div>
                    <div onClick={handleStartDeleteRecord} className='w-full text-[#FF969C] bg-[#FF969C20] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#FF969C30] hover:font-bold select-none cursor-pointer'>
                        Delete
                    </div>
                </div>
            </div>}
            {/*end of edit record popup*/}

            {/*delete record popup*/}
            {deleteRecord&&<div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative' style={{border:'.5px solid #FFFFFF80'}}>
                    <div className='flex flex-row items-center space-between w-full pr-[64px]'>
                        <div className='font-bold'>Confirm Delete?</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={handleCancelDeleteRecord}>X</div>         

                    <div className='flex flex-row items-center space-between w-full mt-10'>
                        <div className='text-sm'>Course Name: {editRecord?.courseName||'-'}</div>          
                    </div>

                    <div className='w-full text-[red] text-xs'>{deleteRecordError}</div>

                    <div onClick={handleCancelDeleteRecord} className='w-full text-[white] bg-[#ffffff20] mt-10 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#ffffff30] hover:font-bold select-none cursor-pointer'>
                        Cancel
                    </div>
                    <div onClick={handleDeleteRecord} className='w-full text-[#FF969C] bg-[#FF969C20] mt-2 py-[6px] flex flex-row item-center justify-center rounded-[99px] hover:bg-[#FF969C30] hover:font-bold select-none cursor-pointer'>
                        Delete
                    </div>
                </div>
            </div>}
            {/*end of delete record popup*/}

            {isLoading&&<Loading/>}
            {popType === 'date' && <div className='fixed inset-0 flex justify-center items-center' style={{backdropFilter:'blur(5px)'}}>
                <div className='p-[24px] bg-[#363636B2] rounded-[8px] flex flex-col text-white relative max-h-[95%] min-w-[350px]' style={{border:'.5px solid #FFFFFF80',overflowY:'auto'}}>
                    <div className='flex flex-row items-center space-between w-full'>
                        <div className='font-bold'>Select a date range*</div>          
                    </div>
                    <div className='absolute top-[24px] right-[24px] font-bold ml-20 cursor-pointer select-none' onClick={() => {setPopType (false)}}>X</div>         
                    <div className='flex flex-row my-4 w-full justify-around'>
                        <div className='w-[50px]'>From:</div>   
                        <input type='date' ref={fromRef} defaultValue={filterFrom} max={filterTo || yearMonthDay(new Date())} onChange={(e) => {handleParamChange('from', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='flex flex-row mb-4 w-full justify-around'>
                        <div className='w-[50px]'>To:</div>   
                        <input type='date' ref={toRef} defaultValue={filterTo} min={filterFrom || undefined} onChange={(e) => {handleParamChange('to', e.target.value)}} className='border rounded-full px-2  min-w-[180px]'style={{colorScheme: 'dark'}}></input>    
                    </div>
                    <div className='w-full flex flex-row items-center justify-between gap-2'>
                        <div className='button_blue w-[50%]' onClick={() => {setPopType(false)}}>Confirm</div>
                        <div className='button_blue w-[50%] text-white' onClick={() => {handleParamChange('clearDate')}}>Clear</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}


export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(Component)

