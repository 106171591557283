import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from 'react-redux';
import { LEFTRIGHT_PADDING } from "../config/style.config";
import {Login} from '../actions/actions'
import LoadingIcon from '../svgs/watchPage/LoadingIcon'
import CheckIcon from '../svgs/watchPage/CheckIcon'
import ErrorIcon from '../svgs/watchPage/ErrorIcon'
import TitleLeftSvg from '../svgs/TitleLeftSvg'
import { fetchSmartWatch, updateWatchSetting } from "../services/watches.service";
import LoadingPage from "../components/Loading";
import ToggleButton from '../components/ToggleButton'


const INTERVAL_MIN= 5
const INTERVAL_MAX=600
const HR_MIN = 1
const HR_MAX = 200

function SmartWatch(props){
    const navigate = useNavigate();
    const [isLoading,setisLoading] = useState(false)
    let [searchParams, setSearchParams] = useSearchParams();

    //paging
    const page =  parseInt(searchParams.get('page'))||1;
    const limit=999;
    const [total,setTotal]=useState(0)
    const [activeTotal,setactiveTotal]=useState(0)
    const totalPage = Math.ceil(total/limit)||1
    //end of paging

    const [data,setData]=useState([])
    const sortBy={modifyDate:-1};
    const fetchSmartWatches=async(p=page,l=limit,st=sortBy)=>{
        let result = await fetchSmartWatch(props.token.token,{'projectId':props.control.projectId},{ skip: l*(p-1), limit:l , sort:st})
        if(result.errno===10001){
            props.dispatch(Login(false))
            return alert('Session Expired');
        }else if(result?.result){
            setData(result?.result);
            setTotal(result?.count||0)
            setactiveTotal(result?.activeCount||0)
        }
    }

    //setting data
    const [setting,setSetting]=useState({})
    const [settingError,setsettingError]=useState('')
    const toggleByFieldId=(fieldId)=>()=>{setSetting({...setting,[fieldId]:(!setting?.[fieldId])})}
    const onInputChange=(fieldId)=>(e)=>{setSetting({...setting,[fieldId]:e.target.value})}
    const selectSmartWatch=(item,index)=>()=>{
        let d = [...data]
        d[index].unSelected= !d[index].unSelected
        setData(d)
    }
    const clearAllselection=()=>{
        let d = [...data]
        d=d.map(i=>({...i,unSelected:true}))
        setData(d)
    }
    //end of setting data
    
    
    useEffect(()=>{
        fetchSmartWatches()
    },[page])

    const [isUpdating,setisUpdating]=useState(false)

    const handleApplySetting=async()=>{
        if(isUpdating)return
        if(setting.isUpdateGPSInterval&&!(setting.updateGPSInterval<=INTERVAL_MAX)){
            setsettingError('Invalid update interval (mins)');return
        }else if(setting.isUpdateGPSInterval&&!(setting.updateGPSInterval>=INTERVAL_MIN)){
            setsettingError('Invalid update interval (mins)');return
        }else if(setting.isUpdateInterval&&!(setting.updateInterval<=INTERVAL_MAX)){
            setsettingError('Invalid update interval (mins)');return
        }else if(setting.isUpdateInterval&&!(setting.updateInterval>=INTERVAL_MIN)){
            setsettingError('Invalid update interval (mins)');return
        }else if(setting.isHeartRate && ( (!(setting.heartRateUpperLimit >= HR_MIN)) || (!(setting.heartRateUpperLimit <= HR_MAX)) || (!(setting.heartRateLowerLimit <= HR_MAX)) || (!(setting.heartRateLowerLimit >= HR_MIN))  ) ){
            setsettingError('Invalid Heart Rate limit (BPM)');return
        }else if(setting.isBloodOxygen && ( (!(setting.bloodOxygenLowerLimit >= 1)) || (!(setting.bloodOxygenLowerLimit <= 100))  ) ){
            setsettingError('Invalid Blood Oxygen limit (%)');return
        }else{
            setsettingError('')
        }
        setisUpdating(true)
        let d = [...data.map(i=>i.unSelected?i:{...i,isLoading:true})]
        setData([...d])
        for(let watch of d){
            if(!watch.unSelected){
                let hasError=false
                if(setting.isUpdateGPSInterval){
                    let result = await updateWatchSetting(props.token?.token,props.control.projectId,watch?.id,'UPDATE_INTERVAL_GPS',{interval:parseInt(setting.updateGPSInterval)||10})
                    hasError=result?.result?.ReturnCode===10002
                        ?'Invalid Device Id'
                        :(!result?.result?.Data?.Success)
                        ?result?.result?.Data?.ErrorMessage||'Unknown Error'
                        :(result?.result?.Data?.Success)
                        ? false
                        :'Unknown Error'       
                }
                if(setting.isUpdateInterval){
                    let result = await updateWatchSetting(props.token?.token,props.control.projectId,watch?.id,'UPDATE_INTERVAL',{interval:parseInt(setting.updateInterval)||10})
                    hasError=result?.result?.ReturnCode===10002
                        ?'Invalid Device Id'
                        :(!result?.result?.Data?.Success)
                        ?result?.result?.Data?.ErrorMessage||'Unknown Error'
                        :(result?.result?.Data?.Success)
                        ? false
                        :'Unknown Error'       
                }
                if((!hasError)&&setting.isFall){
                    let result = await updateWatchSetting(props.token?.token,props.control.projectId,watch?.id,'UPDATE_FALL_ALERT',{})
                    hasError=result?.result?.ReturnCode===10002
                        ?'Invalid Device Id'
                        :(!result?.result?.Data?.Success)
                        ?result?.result?.Data?.ErrorMessage||'Unknown Error'
                        :(result?.result?.Data?.Success)
                        ? false
                        :'Unknown Error'       
                }
                if((!hasError)&&setting.isHeartRate){
                    let result = await updateWatchSetting(props.token?.token,props.control.projectId,watch?.id,'UPDATE_HR_ALERT',{
                        low:parseInt(setting?.heartRateLowerLimit)||50,
                        high:parseInt(setting.heartRateUpperLimit)||130
                    })
                    hasError=result?.result?.ReturnCode===10002
                        ?'Invalid Device Id'
                        :(!result?.result?.Data?.Success)
                        ?result?.result?.Data?.ErrorMessage||'Unknown Error'
                        :(result?.result?.Data?.Success)
                        ? false
                        :'Unknown Error'       
                }

                if((!hasError)&&setting.isBloodOxygen){
                    let result = await updateWatchSetting(props.token?.token,props.control.projectId,watch?.id,'UPDATE_BO_ALERT',{
                        low:parseInt(setting?.bloodOxygenLowerLimit)||80,
                    })
                    hasError=result?.result?.ReturnCode===10002
                        ?'Invalid Device Id'
                        :(!result?.result?.Data?.Success)
                        ?result?.result?.Data?.ErrorMessage||'Unknown Error'
                        :(result?.result?.Data?.Success)
                        ? false
                        :'Unknown Error'       
                }

                if(hasError){
                    watch.isError=true
                    watch.isCompleted=false
                    watch.errorMessage=hasError
                }else{
                    watch.isError=false
                    watch.isCompleted=true
                }
                watch.isLoading=false
                setData([...d])
            }
        }
        setisUpdating(false)
    }

    return(
        <div className='w-full h-full bg-[#0C0D14] flex flex flex-col relative overflow-auto text-[14px] text-white' style={{...LEFTRIGHT_PADDING}}>
            <div className='flex flex-row w-full items-center justify-between mt-[40px]'>
                <div className="flex flex-row">
                    <TitleLeftSvg className='mr-[9px]'/>
                    <div className="text-[24px] text-white font-bold">Smart Watch Setting</div>
                </div>
                <div className="flex flex-row items-center  gap-[8px]">
                    <div className="button_white" onClick={()=>{navigate('/smartwatch/device-management')}}>Cancel</div>
                    <div className="button_blue" style={{opacity:isUpdating?.5:1}} onClick={handleApplySetting}>Apply</div>
                </div>
            </div>

            <div className="flex flex-row flex-wrap w-full mt-[40px] gap-[24px]">
                {/*left*/}
                <div className="flex flex-[4] flex-col p-[16px] gap-[12px] min-w-[520px] max-w-[100%]" style={{border:'.5px solid #ffffff80'}}>
                    <div className="flex flex-row mb-[24px]">
                        <TitleLeftSvg className='mr-[9px]' width={12} />
                        <div className="text-[16px] text-white font-bold">Setting</div>
                    </div>

                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isUpdateInterval} onClick={toggleByFieldId('isUpdateInterval')}/>
                        <div>Update watch data interval</div>
                        <input type="number" min={INTERVAL_MIN} max={INTERVAL_MAX} onChange={onInputChange('updateInterval')} value={setting?.updateInterval||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>Mins</div>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isUpdateGPSInterval} onClick={toggleByFieldId('isUpdateGPSInterval')}/>
                        <div>Update watch GPS interval</div>
                        <input type="number" min={INTERVAL_MIN} max={INTERVAL_MAX} onChange={onInputChange('updateGPSInterval')} value={setting?.updateGPSInterval||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>Mins</div>
                    </div>
                    {/* <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isStandStill} onClick={toggleByFieldId('isStandStill')}/>
                        <div>Enable Standstill alert</div>
                    </div> */}
                    {/* <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isSOS} onClick={toggleByFieldId('isSOS')}/>
                        <div>Enable SOS alert</div>
                    </div> */}
                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isFall} onClick={toggleByFieldId('isFall')}/>
                        <div>Enable FALL Alert</div>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isHeartRate} onClick={toggleByFieldId('isHeartRate')}/>
                        <div>Enable Heart Rate alert when higher than</div>
                        <input onChange={onInputChange('heartRateUpperLimit')} min={HR_MIN} max={HR_MAX} value={setting?.heartRateUpperLimit||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>BPM</div>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <div className="w-[36px] h-[24px]"></div>
                        <div>and when less than</div>
                        <input onChange={onInputChange('heartRateLowerLimit')} min={HR_MIN} max={HR_MAX} value={setting?.heartRateLowerLimit||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>BPM</div>
                    </div>
                    <div className="flex flex-row items-center gap-[12px]">
                        <ToggleButton value={!!setting?.isBloodOxygen} onClick={toggleByFieldId('isBloodOxygen')}/>
                        <div>Enable Blood Oxygen alert when less than</div>
                        <input onChange={onInputChange('bloodOxygenLowerLimit')} min={1} max={100} value={setting?.bloodOxygenLowerLimit||''} className='w-[60px] h-full rounded-full bg-[transparent] px-[10px]'style={{border:'.5px solid #ffffff80'}}/>
                        <div>%</div>
                    </div>
                    <div className="text-[red] text-[10px]">{settingError}</div>
                </div>
                {/*end of left*/}


                {/*right*/}
                <div className="flex flex-[6] flex-col p-[16px] min-w-[520px] max-w-[100%]" style={{border:'.5px solid #ffffff80'}}>
                    <div className="w-full flex flex-row justify-between">
                        <div className="flex flex-row mb-[24px]">
                            <TitleLeftSvg className='mr-[9px]' width={12} />
                            <div className="text-[16px] text-white font-bold">Select Smart Watch</div>
                        </div>
                        <div>
                            <div className="button_blue" onClick={clearAllselection}>
                                Clear All Selections
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap w-full">
                        {data?.map((item,index)=>
                            <div key={item?._id||index} className="flex flex-row w-[50%] cursor-pointer select-none mb-[12px] items-center gap-[8px]" onClick={selectSmartWatch(item,index)}>
                                <div className="w-[15px] h-[15px] flex items-center justify-center border">
                                    {!item.unSelected&&<svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.18182 6.82836L0.795455 4.3209L0 5.15672L3.18182 8.5L10 1.33582L9.20455 0.5L3.18182 6.82836Z" fill="white"/></svg>}
                                </div>
                                {item?.data?.name||item?.id} 
                                {item?.isLoading
                                    ?<div className="animate-spin"><LoadingIcon/></div>
                                    :item?.isCompleted
                                    ?<div><CheckIcon/></div>
                                    :item?.isError
                                    ?<div className="flex flex-row gap-[8px] text-[#FF969C] font-bold text-[12px] flex-1"><ErrorIcon/>{item?.errorMessage}</div>
                                    :<div/>}
                            </div>
                        )}
                    </div>
                </div>
                {/*end of right*/}
            </div>

            


            {isLoading&&<LoadingPage/>}
        </div>
    )
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(SmartWatch)