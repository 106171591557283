import { isHideConfinedSpace, isHideDangerZone, isHideSmartCamera, isHideSmartLock } from "../../config";
import { DEVICE_COLOR_DICT } from "../../config/style.config";
import { DEVICE_TYPE } from "../../dto/devices";

export default function Component(props){
    
    return(
        <div 
            className="absolute top-[4px] d-flex-m-hidden flex-row items-center justify-between text-white cursor-pointer px-[16px] py-[8px] select-none" 
            style={{
                width:540-(isHideConfinedSpace?100:0)-(isHideSmartCamera?100:0)-(isHideDangerZone?100:0)-(isHideSmartLock?100:0),left:`calc(50% - ${270-(isHideSmartCamera?50:0)-(isHideSmartLock?50:0)-(isHideConfinedSpace?50:0)-(isHideDangerZone?50:0)}px)`,
                border:'1px solid #ffffff60',transition:'1s all ease',borderRadius:28,
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(7, 28, 52, 0.85) 100%)',backdropFilter: 'blur(5px)',
            }}
        >
            {isHideSmartCamera||<div className="flex flex-row items-center gap-[4px]">
                <div className="w-[7px] h-[7px] rounded-[4px]" style={{backgroundColor:DEVICE_COLOR_DICT[DEVICE_TYPE.CAMERA]}}/>
                <div className="text-xs text-[#A0B0C0]" style={{}}>AI Camera</div>
            </div>}
            {isHideSmartLock||<div className="flex flex-row items-center gap-[4px]">
                <div className="w-[7px] h-[7px] rounded-[4px]" style={{backgroundColor:DEVICE_COLOR_DICT[DEVICE_TYPE.LOCK]}}/>
                <div className="text-xs text-[#A0B0C0]" style={{}}>Smart lock</div>
            </div>}
            <div className="flex flex-row items-center gap-[4px]">
                <div className="w-[7px] h-[7px] rounded-[4px]" style={{backgroundColor:DEVICE_COLOR_DICT[DEVICE_TYPE.WATCH]}}/>
                <div className="text-xs text-[#A0B0C0]" style={{}}>Smart Watch</div>
            </div>
            {isHideDangerZone||<div className="flex flex-row items-center gap-[4px]">
                <div className="w-[7px] h-[7px] rounded-[4px]" style={{backgroundColor:DEVICE_COLOR_DICT[DEVICE_TYPE.DANGER_ZONE]}}/>
                <div className="text-xs text-[#A0B0C0]" style={{}}>Danger Zone</div>
            </div>}
            {isHideConfinedSpace||<div className="flex flex-row items-center gap-[4px]">
                <div className="w-[7px] h-[7px] rounded-[4px]" style={{backgroundColor:DEVICE_COLOR_DICT[DEVICE_TYPE.CONFINED_SPACE]}}/>
                <div className="text-xs text-[#A0B0C0]" style={{}}>Confined Space</div>
            </div>}
        </div>
    )
}