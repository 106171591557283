import './CreatePopUp.css'
import React, {useEffect, useState } from "react";
import { connect } from 'react-redux';
import DatePickerRange from '../DatePickerRange'
import DatePickerSingle from '../DatePickerSingle'
import {postNewTrackingRecord, postDocumentUploadWithId} from '../../services/httpApiTracking'
import Loading from '../../components/Loading'
//svg
import WhiteCloseSvg from '../../svgs/WhiteCloseSvg'
import InputCalendarSvg from '../../svgs/InputCalendarSvg'
//action
import { formatEndDay, formatStartDay } from '../../actions/actionsDateTime';
function CreatePopUp(props){
    const [formData, setFormData] = useState({equipmentId:"",category:"",projectId:""})
    const [certDateToggle, setCertDateToggle] = useState(false)
    const [certRange, setCertRange] = useState(undefined);
    const [maintDateToggle,  setMaintDateToggle] = useState(false)
    const [maintDate, setmaintDate] = useState(undefined)
    const [examDateToggle, setExamDateToggle] = useState(false)
    const [examDate, setExamDate] = useState(undefined)
    const [file, setFile] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    async function handlePostData(e){
        e.preventDefault()
        setIsLoading(true)
        const postBody = {...formData,projectId:props.control?.projectId}
        // if(certRange === undefined && maintDate === undefined){
        //     alert('Missing Info')
        //     setIsLoading(false)
        //     return;
        // }
        // if(certRange.range === undefined && maintDate.selected !== undefined){ 
        //     alert('Missing Info')
        //     setIsLoading(false)
        //     return;
        // }
        // if(certRange.range.from === undefined || certRange.range.to === undefined){
        //     alert('Missing Info')
        //     setIsLoading(false)
        //     return;
        // }
        if(certRange?.range?.from)postBody.certificateStartDate = formatStartDay(certRange.range.from)
        if(certRange?.range?.to)postBody.certificateEndDate = formatEndDay(certRange.range.to)
        if(maintDate?.selected)postBody.maintenanceDate = formatStartDay(maintDate.selected)
        postBody.projectId = props.control.projectId
        // send post request to create new document
        const docRes = await postNewTrackingRecord(props.token.token, postBody)
        if(docRes.errno) {
            alert('Error creating documents')
            setIsLoading(false)
        }
        const insertId = docRes.result.insertedId
        const res = await postDocumentUploadWithId(props.token.token, insertId, file)
        if(res.errno) {
            alert('Error uploading file')
            setIsLoading(false)
        }
        props.setCreatePopUp(false)
        setIsLoading(false)
    }
    function changeFormData(key, e){
        if(key === 'file') return setFile(e.target.files[0])
        setFormData({...formData, [key]:e.target.value})
    }
    function rangeSetter(any){
        setCertRange(any)
    }
    function maintDateSetter(any){
        setmaintDate(any)
    }
    function examDateSetter(any){
        setExamDate(any)
    }
    return (
        <div className="w-[25%] min-w-[460px] h-[80%] min-h-[813px] border border-[#FFFFFF80] rounded bg-[#363636B2] p-[40px] text-white" style={{zIndex:99}}>
            {isLoading && <Loading />}
            <div className="flex flex-row justify-between mb-8">
                <div className="text-[18px] font-bold">Add New Device/Item</div>
                <WhiteCloseSvg className='cursor-pointer' onClick={() => {props.setCreatePopUp(false)}}/>
            </div>
            <form onSubmit={handlePostData} className="flex flex-col">
                {/* <div className="flex flex-col mb-4">
                    <label htmlFor="projectId">ProjectId*</label>
                    <input type="text" id="projectId" name="projectId" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('projectId', e)}}/>
                </div> */}
                <div className="flex flex-col mb-4">
                    <label htmlFor="category">Category*</label>
                    <input type="text" id="category" name="category" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('category', e)}}/>
                </div>
                <div className="flex flex-col mb-4">
                    <label htmlFor="equipmentId">Equipment ID*</label>
                    <input type="text" id="equipmentId" name="equipmentId" className='rounded-full bg-transparent h-[42px] mt-[8px] px-4' style={{borderColor:'#FFFFFF80', borderWidth: 1}} value={formData.name} onChange={(e) => {changeFormData('equipmentId', e)}}/>
                </div>
                <div className="flex flex-col mb-4 relative">
                    <label htmlFor="certificateDateRange">Certificate Date</label>
                    <div className='prevent-select w-full h-[40px] text-white flex flex-row items-center px-2 rounded-full bg-transparent h-[42px] mt-[8px] px-4 cursor-pointer hover:scale-105' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onClick={() => setCertDateToggle(!certDateToggle)}>
                        <InputCalendarSvg className='mr-2' />{certRange?.display ? certRange?.display.from+ ' To '  + certRange?.display.to : 'Click to select date'}
                    </div>
                    {certDateToggle && <div style={{zIndex:99}}>
                        <div className='fixed inset-0 bg-[#00000080]' onClick={()=>{setCertDateToggle(false)}}/>
                        <DatePickerRange setter={rangeSetter}/>
                    </div>}
                </div>
                <div className="flex flex-col mb-4 relative">
                    <label htmlFor="certificateDateRange">Checking & Maintainance</label>
                    <div className='prevent-select w-full h-[40px] text-white flex flex-row items-center px-2 rounded-full bg-transparent h-[42px] mt-[8px] px-4 cursor-pointer hover:scale-105' style={{borderColor:'#FFFFFF80', borderWidth: 1}} onClick={() => setMaintDateToggle(!maintDateToggle)}>
                    <InputCalendarSvg className='mr-2' /> {maintDate?.display ? maintDate.display : 'Click to select date'}
                    </div>
                    {maintDateToggle && <div style={{zIndex:99}}>
                        <div className='fixed inset-0 bg-[#00000080]' onClick={()=>{setMaintDateToggle(false)}}/>
                        <DatePickerSingle setter={maintDateSetter} style={{top: -350, zIndex:99}}/>
                    </div>}
                </div>
                <div className="flex flex-col mb-6">
                    <label htmlFor="file" className='flex flex-col'>
                        <p>File Attachment (0)</p>
                        <p className='w-full py-[8px] mt-[8px] text-white text-center rounded-full cursor-pointer hover:scale-105' style={{borderWidth:1, borderColor:'#FFFFFF80'}}>Upload File</p>
                    </label>
                    <input type="file" id="file" name="file" onChange={(e) => {changeFormData('file', e)}} value={formData.name} className='w-full' style={{display:'none'}}/>
                </div>
                <div className="flex flex-col mb-4">
                    <input type="submit" className='bg-[#00202A] text-[#63BDFF] border-none h-[40px] rounded-full cursor-pointer hover:scale-105' id="submitBtn" name="submit" value='Add New'/>
                </div>
            </form>
        </div>
    )
}
export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(CreatePopUp)