import React  from 'react';
import { connect } from 'react-redux';
import TickSvg from '../../../../svgs/permit/FormTick'
//svgs
function FormTable({isPreview, sectionIdx, fieldIdx, fieldObj, updateFieldData}) {
    const {fieldData, fieldId, fullWidth, required, tableTitle} = fieldObj
    function handleUpdateTable(colIdx, cellIdx, val){
        const tempObj = [...fieldData]
        tempObj[colIdx].colData[cellIdx] = val
        updateFieldData(sectionIdx, fieldIdx, tempObj)
    }
    return (
    <div className='w-full mt-[24px]'>
        <div className=''>{tableTitle || ''}</div>
        <div className='flex flex-col w-full'>
            <div className='flex flex-row w-full'>
                {
                    fieldData.map((columnObj, columnIdx) => {
                        const {type:colType, title, colData} = columnObj
                        return (
                            <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold flex-1' style={{flex: columnObj.type === 'checkbox' ? 1 : 2, paddingLeft: columnIdx === 0 ? 32 : 12, paddingRight: columnIdx === (fieldData.length - 1) ? 32 : 12}}>{title}</div>
                        )
                    })
                }
            </div>
            {fieldData?.[0]?.colData?.map((row,cellIdx)=>
                <div key={cellIdx} className='w-full flex flex-row justify-between'>
                    {fieldData.map((columnObj,columnIdx)=>
                        <div key={fieldId + columnIdx + cellIdx} className='flex flex-row items-center min-h-[60px] px-[12px] py-[8px] text-wrap overflow-auto break-words' style={{flex: columnObj.type === 'checkbox' ? 1 : 2, backgroundColor: cellIdx % 2 === 0 ? '#FFFFFF0D' :'transparent', paddingLeft: columnIdx === 0 ? 32 : 12, paddingRight: columnIdx === (fieldData.length - 1) ? 32 : 12}}>
                            {columnObj.type === 'text' && <>
                                <div className='w-full'>{columnObj.colData[cellIdx]}</div>
                            </>
                            }
                            {columnObj.type === 'checkbox' && <>
                                <label className='relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'>
                                    <input onChange={(e) => handleUpdateTable(columnIdx, cellIdx, e.target.checked)} className='absolute opacity-0 cursor-pointer' type="checkbox" />
                                    {/* <div style={{backgroundColor: fieldData[columnIdx].colData[cellIdx] ? 'white' : 'transparent'}}className='h-full w-full'></div> */}
                                    <TickSvg width={24} style={{opacity: columnObj.colData[cellIdx] ? 100 : 0}}/>

                                </label>
                                <div>{fieldData[columnIdx].colData[cellIdx]}</div>
                            </>}
                            {columnObj.type === 'Text input' && <>
                                <input disabled={isPreview} value={columnObj.colData[cellIdx]} onChange={(e) => handleUpdateTable(columnIdx, cellIdx, e.target.value)}className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-full'></input>
                            </>}
                        </div>
                    )}
                </div>
            )}
        </div>
        {/* <div className='flex flex-row w-full'>
            {fieldData.map((columnObj, columnIdx) => {
                const {type:colType, title, colData} = columnObj
                return (<div key={colType + columnIdx} style={{flex: colType === 'checkbox' ? 1 : 2}}>
                    <div className='flex flex-row items-center h-[60px] px-[12px] overflow-auto text-[10px] font-bold' style={{paddingLeft: columnIdx === 0 ? 32 : 12, paddingRight: columnIdx === (fieldData.length - 1) ? 32 : 12}}>{title}</div>
                    {colData.map((cell, cellIdx) => (
                        <div key={fieldId + columnIdx + cellIdx} className='flex flex-row items-center h-[60px] px-[12px] overflow-auto break-words' style={{backgroundColor: cellIdx % 2 === 0 ? '#FFFFFF0D' :'transparent', paddingLeft: columnIdx === 0 ? 32 : 12, paddingRight: columnIdx === (fieldData.length - 1) ? 32 : 12}}>
                            {colType === 'text' && <div>{cell}</div>}
                            {colType === 'checkbox' && <>
                                <label className='relative w-[24px] h-[24px] p-[1px] border mr-[12px] flex flex-row items-center justify-center'>
                                    <input onChange={(e) => handleUpdateTable(columnIdx, cellIdx, e.target.checked)} className='absolute opacity-0 cursor-pointer' type="checkbox" />
                                    <div style={{backgroundColor: fieldData[columnIdx].colData[cellIdx] ? 'white' : 'transparent'}}className='h-full w-full'></div>
                                    <TickSvg width={24} style={{opacity: fieldData[columnIdx].colData[cellIdx] ? 100 : 0}}/>

                                </label>
                                <div>{cell}</div>
                            </>}
                            {colType === 'Text input' && <>
                                <input disabled={isPreview} value={colData[cellIdx]} onChange={(e) => handleUpdateTable(columnIdx, cellIdx, e.target.value)}className='border border-[#FFFFFF80] rounded-full bg-transparent h-[33px] px-[12px] w-full'></input>
                            </>}
                        </div>
                    ))}
                </div>)
            })}
        </div> */}
    </div>)
}

export default connect((store) => {
    return {
      token: store.tokenReducer,
      control: store.controlReducer,
    }
  })(FormTable)