export function validateItemWithDate(start, end){
    const today = new Date()
    const s = new Date(start)
    const n = new Date(end)
    return today >= s && today <= n
}

export function getDateOnly(date){
    if(!date) return ''
    const newDate = new Date(date)
    return newDate.toLocaleDateString()
}

export function getTimeOnly(date){
    const time = new Date(date)
    return time.toLocaleTimeString()
}

export function formatEndDay(date){
    const time = date.getTime()+ 1000*60*60*24-1000
    return new Date(time)
}
export function formatStartDay(date){
    const start = new Date(date)
    return start
}

export function yearMonthDay(date){
    if(date === undefined) return ''
    const dateObj = new Date(date);
    const month   = dateObj.getUTCMonth() + 1; // months from 1-12
    const day     = dateObj.getUTCDate();
    const year    = dateObj.getUTCFullYear();

    const newDate = `${year}-${month}-${day}`;
    
    const pMonth        = month.toString().padStart(2,"0");
    const pDay          = day.toString().padStart(2,"0");
    const newPaddedDate = `${year}-${pMonth}-${pDay}`;

    return newPaddedDate
}

export function handleFilterDate(key, from, to){
    if(from && to){
        let fromDate = new Date(new Date(from).setHours(0,0,0,0))
        let toDate = new Date(new Date(to).setHours(0,0,0,0))
        toDate.setDate(toDate.getDate() + 1)
        return {"$gte": fromDate.getTime(), "$lte": toDate.getTime()}
    }
    if(key === '') return
    const minusObj = {
        'Today': 1,
        '7 Days': 7,
        '30 Days': 30,
    }
    let today = new Date()
    today = new Date(new Date().setHours(0,0,0,0))
    today.setDate(today.getDate() + 1)
    const pass  = new Date(today)
    pass.setDate(pass.getDate() - minusObj[key])
    return {"$gte": pass.getTime(), "$lte": today.getTime()}
}
export function handleFilterDateText(key, from, to){
    if(from && to){
        let fromDate = new Date(new Date(from).setHours(0,0,0,0))
        let toDate = new Date(new Date(to).setHours(0,0,0,0))
        toDate.setDate(toDate.getDate() + 1)
        return {"$gte": fromDate, "$lte": toDate}
    }
    if(key === '') return
    const minusObj = {
        'Today': 1,
        '7 Days': 7,
        '30 Days': 30,
    }
    let today = new Date()
    today = new Date(new Date().setHours(0,0,0,0))
    today.setDate(today.getDate() + 1)
    const pass  = new Date(today)
    pass.setDate(pass.getDate() - minusObj[key])
    return {"$gte": pass, "$lte": today}
}
